<template>
	<section v-loading.fullscreen.lock="requestsCount>0">
		<router-view />
	</section>
</template>

<script>
import {
	defineComponent,
	ref,
	reactive
} from 'vue'
import { mapGetters } from 'vuex'
export default defineComponent({
	computed: {
		...mapGetters([
			'requestsCount'
		])
	}
});
</script>

<style>
@import "../public/css/main.css";
@import "../public/css/color-dark.css";
</style>
<style lang="scss" scoped>
section{
	width: 100%;
	height: 100%;
}
</style>
<style>
.icon {
	width: 1em; height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}
</style>
