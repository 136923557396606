import {
	createStore
} from "vuex"
import api from '@/api/index'
import utils from "@/utils"
const { cookie } = utils

export default createStore({
	state: {
		collapse: true,  // 是否折叠侧边栏
		requestsCount: 0, // 请求数量
		bondList: [], // 转债列表，包含id主要用来收藏等
		userInfo: {},  // 用户信息
		allPlates: [], // 所有板块数据
		dataExpirationDate: '',  // 是否购买了数据
	},
	getters: {
		requestsCount(state){
			return state.requestsCount
		},
		// 数据是否有效
		isValidity(state){
			return state.dataExpirationDate && new Date() < (new Date(state.dataExpirationDate))
		}
	},
	mutations: {
		// 侧边栏折叠
		hadndleCollapse(state, data) {
			state.collapse = data;
		},

		// 增减请求数量
		setRequest(state, isFinish){
			// state.requestsCount++
			if (!isFinish) {
				state.requestsCount++
			} else {
				state.requestsCount--
			}
			if (state.requestsCount < 0) state.requestsCount=0 
		},

		// 清空请求数量
		clearRequest(state){
			state.requestsCount = 0
		},

		// 保存转债列表
		setBondList(state, bondList){
			state.bondList=bondList
		},

		// 设置用户信息
		setUserInfo(state, userInfo){
			state.userInfo=userInfo
		},

		// 设置所有板块数据
		setAllPlates(state, allPlates){
			state.allPlates=allPlates
		},

		// 设置数据状态
		setDateStatus(state, dataExpirationDate){
			state.dataExpirationDate=dataExpirationDate
		},
	},
	actions: {
		// 获取转债列表
		async getBondList({ commit, state }){
			return new Promise(async (resolve, reject)=>{
				if (!state.bondList || !state.bondList.length) {
					let res=await api.getBondList({
						page: 1,
						limit: 1000
					})
					if (res) {
						commit('setBondList', res.rows)
						resolve(res.rows)
					} else {
						reject()
					}
				}
				resolve(false)
			})
		},

		// 获取用户信息
		async getUserInfo({ commit, state }, args={}){
			return new Promise(async (resolve, reject)=>{
				let res=await api.getScore({
					...args,
					noMessage:{
						noSuccess: 1
					}
				})
				if (res) {
					commit('setUserInfo', res)
					resolve(res)
				} else {
					reject()
				}
				resolve(false)
			})
		},

		// 设置所有板块
		async getAllPlates({ commit, state }, args={}){
			return new Promise(async (resolve, reject)=>{
				if (!state.allPlates || !state.allPlates.length) {
					let res=await api.getAllPlates({
						...args,
						// noMessage:{
						// 	noSuccess: 1
						// }
					})
					if (res) {
						let data=res.map(i=>({
							label: i,
							value: i
						}))
						commit('setAllPlates', data)
						resolve(data)
					} else {
						reject()
					}
				}
				resolve(false)
			})
		},

		// 获取是否购买数据状态
		async getBuyDataStatus({ commit, state, getters }){
			return new Promise(async (resolve, reject)=>{
				const token=cookie.getCookie('token')
				// 是否有效
				// const isValidity=state.dataExpirationDate && new Date() < (new Date(state.dataExpirationDate))
				const isValidity=getters.isValidity
				if (!isValidity && token) {
					let res=await api.getScore({
						token,
						noMessage:{
							noSuccess: 1
						}
					})
					if (res) {
						commit('setDateStatus', res.multifactorial_time)
						resolve(res.multifactorial_time)
					} else {
						reject()
					}
				}
				resolve(false)
			})
		}
	},
	modules: {}
})