export default [
  {
    path: "/basedata",
    name: "basedata",
    meta: {
      keepAlive: true,
      title: "多因子表",
      icon: "icon-yinzifenbu",
    },
    component: () => import("@/views/modules/basedata/index.vue")
  },
  {
    path: "/basedata_v1_1",
    name: "basedata_v1_1",
    meta: {
      keepAlive: true,
      title: "多因子v1.1",
      icon: "icon-dashuju",
    },
    component: () => import("@/views/modules/basedata_v1_1/index.vue")
  },
  {
    path: "/five_factor",
    name: "five_factor",
    meta: {
      keepAlive: true,
      title: "五因子表",
      icon: "icon-shuju",
    },
    component: () => import( "@/views/modules/fiveFactor/index.vue")
  },
  {
    path: "/db_low",
    name: "db_low",
    meta: {
      keepAlive: true,
      title: "双低表",
      icon: "icon-shuju1",
    },
    component: () => import( "@/views/modules/dbLow/index.vue")
  },
  {
    path: "/power",
    name: "power",
    meta: {
      keepAlive: true,
      title: "超低空",
      icon: "icon-shuju01",
    },
    component: () => import( "@/views/modules/power/index.vue")
  },
  // {
  //   path: "/suggest",
  //   name: "suggest",
  //   meta: {
  //     keepAlive: true,
  //     title: "推荐数据",
  //     icon: "icon-shuju01",
  //   },
  //   component: () => import( "@/views/modules/suggest/index.vue")
  // },
  // {
  //   path: "/prebond",
  //   name: "prebond",
  //   meta: {
  //     keepAlive: true,
  //     title: "待发",
  //     icon: "icon-dengdai",
  //   },
  //   component: () => import("@/views/modules/prebond/index.vue")
  // },
  {
    path: "/sellback",
    name: "sellback",
    meta: {
      keepAlive: true,
      title: "回售",
      icon: "icon-huishou",
    },
    component: () => import("@/views/modules/sellback/index.vue")
  },
  {
    path: "/redeem",
    name: "redeem",
    meta: {
      keepAlive: true,
      title: "强赎",
      icon: "icon-qiangzhizhongzhi",
    },
    component: () => import("@/views/modules/redeem/index.vue")
  },
  {
    path: "/tactics",
    name: "tactics",
    meta: {
      keepAlive: true,
      title: "组合信息",
      icon: "icon-xiaoguoyouxiu",
    },
    component: () => import("@/views/modules/tactics/index.vue")
  },
  {
    path: "/show",
    name: "show",
    meta: {
      keepAlive: true,
      title: "学员组合",
      icon: "icon-xuesheng",
    },
    component: () => import("@/views/modules/show/index.vue"),
  },
  {
    path: "/my_home",
    name: "my_home",
    meta: {
      auth: true,
      title: "个人主页",
      icon: 'icon-geren'
      
    },
    component: () => import("@/views/modules/myHome/index.vue"),
  }
]