import request from '../request';
import config from '@/config/index'
const { apiPath } = config


// 生成邀请链接
export const createInvite = data => {
	return request({
		url: `${apiPath('api')}/invitation/create`,
		method: 'post',
		data
	});
};

// 邀请列表
export const getInviteSist = data => {
	return request({
		url: `${apiPath('api')}/invitation/list`,
		method: 'post',
		data
	});
};


// 用户取消转债收藏
export const cancalBondCollect = data => {
	return request({
		url: `${apiPath('api')}/bondfav/cancel`,
		method: 'post',
		data
	});
};


// 用户取消转债收藏
export const addBondCollect = data => {
	return request({
		url: `${apiPath('api')}/bondfav/create`,
		method: 'post',
		data
	});
};

// 获取用户收藏列表
export const getBondCollectList = data => {
	return request({
		url: `${apiPath('api')}/bondfav/list`,
		method: 'post',
		data: {
			...data,
			noMessage: {
				noSuccess: true,  // 成功不提示
			}
		}
	});
};

// 可转债接口
export const getBondList = data => {
	return request({
		url: `${apiPath('api')}/bond/list`,
		method: 'post',
		data: {
			...data,
			noMessage: {
				noSuccess: true,  // 成功不提示
			}
		}
	});
};

// 重置密码
export const resetPassword = data => {
	return request({
		url: `${apiPath('api')}/user/resetpwd`,
		method: 'post',
		data
	});
};

// 购买当天数据
export const buyData = data => {
	return request({
		url: `${apiPath('api')}/score/Data`,
		method: 'post',
		data
	});
};

// 获取当前积分
export const getScore = data => {
	return request({
		url: `${apiPath('api')}/score/User`,
		method: 'post',
		data
	});
};


