import request from '../request';
import config from '@/config/index'
const { apiPath } = config

// 获取基础数据
export const getBasedata = params => {
	return request({
		url: `${apiPath()}/getAllData`,
		method: 'get',
		params
	});
};


// 下载数据
export const downloadFile = () => {
	return request({
		url: `${apiPath()}/download`,
		method: 'get',
		// 不设置流模式，会乱码
		responseType: 'arraybuffer',
		params: {
			noMessage: {
				download: 1
			}
		}
	});
};