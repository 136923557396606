import request from '../request';
import config from '@/config/index'
const { apiPath } = config

// 获取待发
export const getPreBond = params => {
	return request({
		url: `${apiPath()}/getBondInfo/preBond`,
		method: 'get',
		params
	});
};
