import request from '../request';
import config from '@/config/index'
const { apiPath } = config

// 获取所有板块数据
export const getAllPlates = params => {
	return request({
		url: `${apiPath()}/getAllPlates`,
		method: 'get',
		params
	});
};


// 获取当天指数
export const getTodayIndexs = params => {
	return request({
		url: `${apiPath('api')}/index/quote`,
		method: 'get',
		params
	});
};

// 获取指数历史
export const getHistoryIndexs = params => {
	return request({
		url: `${apiPath('api')}/index/list`,
		method: 'get',
		params
	});
};

// 获取盘口行情
export const getTape = params => {
	return request({
		url: `${apiPath('api')}/sina/stock`,
		method: 'get',
		params
	});
};