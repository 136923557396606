import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from "vue-router";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from "@/store"
import utils from "@/utils"
import {
	ElMessage
} from 'element-plus'
const { cookie, isLoginStatus } = utils
import modules from './modules'

// 不用鉴权的页面
const whiteList=[
	'/login',
	'/registered',
	// '/basedata',
	// '/five_factor',
	// '/prebond',
	// '/sellback',

]


const routes:any[] = [{
		path: '/',
		redirect: '/layout'
	}, {
		path: "/layout",
		name: "layout",
		redirect: "/basedata",
		component: () => import("@/views/layout/index.vue"),
		children: [
			...modules
		]
	}, 
	{
		path: "/login",
		name: "login",
		meta: {
			title: '登录'
		},
		component: () => import(
			"@/views/login/index.vue")
	},
	{
		path: "/registered",
		name: "registered",
		meta: {
			title: '注册'
		},
		component: () => import(
			"@/views/registered/index.vue")
	},
	{
		path: "/indexs",
		name: "indexs",
		meta: {
			title: '指数'
		},
		component: () => import(
			"@/views/indexs/index.vue")
	},
	{
		path: '/:pathMatch(.*)',
		name: '404',
		meta: {
			title: '找不到页面'
		},
		component: () => import( /* webpackChunkName: "404" */
			'@/views/404/404.vue')
	}
];

const router = createRouter({
	// history: createWebHistory(process.env.BASE_URL),
	history: createWebHashHistory(),
	routes
});

router.beforeEach((to, from, next) => {
	// document.title = `${to.meta.title} | 转债容易，佛系投资`;
	NProgress.start()
	document.title = `易债网 | 转债容易，佛系投资`;
	store.commit('clearRequest')
	isLoginStatus() && !Object.keys(store.state.userInfo).length && store.dispatch('getUserInfo')

	// 鉴权【目前只做登录权限】
	// 在白名单或者没有标识权限都可以
	if (whiteList.includes(to.path) || !to.meta.auth) {
		next()
	} else {
		if (isLoginStatus()) {
			// 登录就给过，后续可以做其他权限
			next()
		} else {
			ElMessage.error('您还未登录，请登录后操作')
			setTimeout(()=>{
				next({
					path: '/login',
					query: {
						...to.query,
						redirect: to.path
					}
				})
			})
		}
	}
});

router.afterEach((to, from)=>{
	NProgress.done()
})

export default router;