import request from '../request';
import config from '@/config/index'
const { apiPath } = config


// 生成微信订单
export const createWechatOrder = data => {
	return request({
		url: `${apiPath('api')}/payw/create`,
		method: 'post',
		data: {
			...data,
			noMessage: {
				noSuccess: true,  // 成功不提示
			}
		}
	});
};

// 查询微信订单
export const checkWechatOrder = data => {
	return request({
		url: `${apiPath('api')}/payw/check`,
		method: 'post',
		data: {
			...data,
			noMessage: {
				noFail: true,   // 错误不提示
				noLoading: true,  // 不显示loading
			}
		}
	});
};