import request from '../request';
import config from '@/config/index'
const { apiPath } = config

// 获取策略数据
export const getUserStrategys = params => {
	return request({
		url: `${apiPath()}/getUserStrategys/${params.path}`,
		method: 'get',
		// params
	});
};
