import axios from 'axios';
import store from '@/store'
import {
	refresh
} from './modules/login'
import {
	ElMessage
} from 'element-plus'
import utils from '@/utils'
const {
	cookie
} = utils

// 是否不需要提醒
function noMessage(config, type='response'){
	if (config.method=='post') {
		if (type=='response') {
			return config.data && JSON.parse(config.data)['noMessage'] || {}
		} else {
			return config.data && config.data['noMessage'] || {}
		}
		
	} else {
		return config.params && config.params['noMessage'] || {}
	}
}

// 刷新token
async function refreshToken() {
	let res = await refresh({})
	if (res) {
		const {
			token,
			expires_in
		} = res
		// console.log(new Date().valueOf())
		cookie.setCookie('refreshTime', new Date().valueOf(), expires_in / 60 / 60 / 24)
		cookie.setCookie('token', token, 100)
	}
	return Promise.resolve()
}

function isExpired() {
	return !cookie.getCookie('refreshTime')
}

const service = axios.create({
	timeout: 10000
});

service.interceptors.request.use(
	async config => {
			let token = cookie.getCookie('token')
			// 不用刷新token的url
			const notRefresh = [
				'/token/refresh',
				'/user/login',
				'/user/mobilelogin',
				'/sms/send',
			]
			if (token && isExpired() && notRefresh.every(v => !config.url.includes(v))) {
				await refreshToken()
				token = cookie.getCookie('token')
			}
			// 配置请求头
			Object.assign(config.headers, {
				token
			})
			!noMessage(config, 'request')['noLoading'] && store.commit("setRequest", false)
			return config;
		},
		error => {
			console.log(error);
			return Promise.reject();
		}
);

service.interceptors.response.use(
	response => {
		const { config } = response
		!noMessage(config)['noLoading'] && store.commit("setRequest", true)
		// console.log(response)
		if (response.status === 200) {
			// 下载文件
			if (noMessage(config)['download']) return response
			
			// 错误提示
			if (response.data.hasOwnProperty('code') && response.data.code != 1 ||
				response.data.hasOwnProperty('error_code') && response.data.error_code != 0) {
					!noMessage(config)['noFail'] && ElMessage.error(response.data.msg || '网络错误')
				return null
			}

			// 成功提示
			// console.log(response)
			if (config.method == 'post') {
				!noMessage(config)['noSuccess'] && ElMessage.success(response.data.msg)
			}
			return response.data.data;

		} else {
			ElMessage.error(response.data.msg || '网络错误')
			Promise.reject();
		}
	},
	error => {
		!noMessage(error.response && error.response.config || {})['noLoading'] && store.commit("setRequest", true)
		const { config } = error

		let ignore=['/getAllData']
		if (ignore.every(api=>!config.url.includes(api))) ElMessage.error(error.response && error.response.data.msg || '网络错误')
		console.log(error);
		return reRequest(config, (()=>{
			if (config.hasOwnProperty('reCount')) {
				return config.reCount-1
			} else {
				return 3
			}
		})())
		// return Promise.reject();
	}
);


function reRequest(config, reCount){
	if (reCount>1) {
		return service({...config, reCount})
	} 
	return false
}

export default service;