export default {
  apiPath(type='ezhai'){
    const env=process.env.VUE_APP_API_ENV
    switch(env) {
      // 测试环境
      case 'test': {
        return {
          ezhai: 'https://api1.ezhai.net.cn/ezhai',  // python的接口
          api: 'https://api3.ezhai.net.cn'   // php的接口
        }[type]
      }
      // 正式环境
      default: {
        return {
          ezhai: 'https://api1.ezhai.net.cn/ezhai',  // python的接口
          api: 'https://api3.ezhai.net.cn'   // php的接口
        }[type]
      }
    }
    
  },   // api共用部分
}