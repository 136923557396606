import request from '../request';
import config from '@/config/index'
const { apiPath } = config

// 获取回售
export const getSellBack = params => {
	return request({
		url: `${apiPath()}/getBondInfo/sellBack`,
		method: 'get',
		params
	});
};
