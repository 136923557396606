import store from "@/store";
// 获取行情图
function getMarketImg({
  symbol,
  type
}) {
  const types = {
    daily: `http://image.sinajs.cn/newchart/daily/n/${symbol}.gif`, // 日k
    min: `http://image.sinajs.cn/newchart/min/n/${symbol}.gif`, // 实时行情
  }
  return types[type]
}

// 沪深标的添加前缀
function addPrefix(code) {
  const prefix = code.substring(0, 1);
  switch (prefix) {
    case '1': {
      // 可转债
      const prefix = code.substring(0, 2);
      switch (prefix) {
        case '12': {
          return `SZ${code}`
        }
        default: {
          return `SH${code}`
        }
      }
    }
    default: {
      // 股票
      switch (prefix) {
        case '0':
        case '2':
        case '3': {
          return `SZ${code}`
        }
        default: {
          return `SH${code}`
        }
      }
    }
  }
}

// cookie方法封装
const cookie = {
  //设置cookie,增加到vue实例方便全局调用
  setCookie: (c_name, value, expiredays) => {
    let exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    let cookie =
      c_name +
      "=" +
      escape(value) +
      // (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
      (expiredays == null ? "" : ";expires=" + exdate.toUTCString());
    
    // 项目特需，用于多项目共用cookie，父域名种cookie
    if(!location.host.includes('localhost') && !location.host.includes('192.168')) cookie+=';domain=.ezhai.net.cn'
    document.cookie=cookie
  },

  //获取cookie、
  getCookie: (name) => {
    let arr,
      reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if ((arr = document.cookie.match(reg))) return arr[2];
    else return null;
  },

  //删除cookie
  delCookie: (name) => {
    let exp = new Date();
    exp.setTime(exp.getTime() - 1);
    let cval = cookie.getCookie(name);
    if (cval != null) {
      let cookie = name + "=" + cval + ";expires=" + exp.toUTCString();
      // 项目特需，用于多项目共用cookie，父域名种cookie
      if(!location.host.includes('localhost') && !location.host.includes('192.168')) cookie+=';domain=.ezhai.net.cn'
      document.cookie=cookie
    }
  },

  // 清除所有cookie
  clearAllCookie() {
    var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if (keys) {
      for (var i = keys.length; i--;) {
        let cookie = keys[i] + "=0;expires=" + new Date(0).toUTCString();
        if(!location.host.includes('localhost') && !location.host.includes('192.168')) cookie+=';domain=.ezhai.net.cn'
        document.cookie=cookie
      }
    }
  },
}

// 获取用户信息
function getUserInfo(){
  // let userInfo=localStorage.getItem('userInfo')
  // return userInfo && JSON.parse(userInfo) || {}
  return store.state.userInfo
}
// 保存个人信息
function saveUserInfo(userInfo){
  store.dispatch('getUserInfo')
  // localStorage.setItem('userInfo', JSON.stringify(userInfo))
}
// 清除个人信息
function logout(){
  cookie.delCookie('refreshTime')
  cookie.delCookie('token')
  localStorage.removeItem('userInfo')
}

// 是否登录
function isLoginStatus(){
  return cookie.getCookie('refreshTime') && cookie.getCookie('token')
}

// 随机数定时器
function randomTimer({
  context,  // 传入调用位置的this
  timerField,  // 调用位置该定时器字段
  max=5000, // 毫秒
  min=3000,
  fn,  // 回调
}){ 
  if (!fn) return
  let random=Math.random()*(max-min)+min
  context[timerField]=setTimeout(()=>{
    fn()
    randomTimer({
      context,
      timerField,
      max,
      min,
      fn
    })
  }, random)
}


// 是否手机
function isPhone(){
  const device=['Android', 'iPhone']
  const ua=navigator.userAgent
  return device.some(d=>ua.includes(d))
}


export default {
  getMarketImg,
  addPrefix,
  cookie,
  getUserInfo,
  saveUserInfo,
  logout,
  isLoginStatus,
  randomTimer,
  isPhone
}