import request from '../request';
import config from '@/config/index'
const { apiPath } = config

// 注册
export const register = data => {
	return request({
		url: `${apiPath('api')}/user/register`,
		method: 'post',
		data
	});
};


// 登录
export const login = data => {
	return request({
		url: `${apiPath('api')}/user/login`,
		method: 'post',
		data
	});
};

// 退出
export const logout = data => {
	return request({
		url: `${apiPath('api')}/user/logout`,
		method: 'post',
		data
	});
};

// 手机号登录
export const mobilelogin = data => {
	return request({
		url: `${apiPath('api')}/user/mobilelogin`,
		method: 'post',
		data
	});
};

// 发送验证码
export const sendCode = data => {
	return request({
		url: `${apiPath('api')}/sms/send`,
		method: 'post',
		data
	});
};


// 手机号登录
export const refresh = data => {
	return request({
		url: `${apiPath('api')}/token/refresh`,
		method: 'post',
		data
	});
};


// 发送邮箱验证码
export const sendEmailCode = data => {
	return request({
		url: `${apiPath('api')}/ems/send`,
		method: 'post',
		data
	});
};

