import * as basedata from './modules/basedata'
import * as tactics from './modules/tactics'
import * as preBond from './modules/preBond'
import * as redeem from './modules/redeem'
import * as sellBack from './modules/sellBack'
import * as show from './modules/show'
import * as login from './modules/login'
import * as user from './modules/user'
import * as pay from './modules/pay'
import * as common from './modules/common'


export default {
	...basedata,
	...tactics,
	...preBond,
	...redeem,
	...sellBack,
	...show,
	...login,
	...user,
	...pay,
	...common
}