import request from '../request';
import config from '@/config/index'
const { apiPath } = config

// 获取强赎
export const getRedeem = params => {
	return request({
		url: `${apiPath()}/getBondInfo/redeem`,
		method: 'get',
		params
	});
};
