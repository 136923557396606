import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../public/css/reset.css'
import installElementPlus from './plugins/element'
import '../public/css/icon.css'
import utils from './utils'

// iconfont
import '../public/iconfont/iconfont.js'
import '../public/iconfont/iconfont.css'

import api from './api/index'

// 跳转手机
if (utils.isPhone()) location.replace('https://h5.ezhai.net.cn')


const app = createApp(App)
app.config.globalProperties.$api = api
installElementPlus(app)
app
	.use(store)
	.use(router)
	.mount('#app')